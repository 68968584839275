import {Suspense, Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch, Routes} from 'react-router-dom';
import {AuthRouteHelper, ProtectedRouteHelper, PublicRouteHelper} from './helpers.js';
import { AuthorizedRoutes, ProtectedRoutes, PublicRoutes } from './routes.js';
import Layout from '../container/layouts/index.js';
import ScrollToTop from '../components/ScrollToTop';

function AppRoutes({isAuthenticated}) {
  return (
    <Router>
        <Fragment>
            <ScrollToTop />
            <Suspense fallback={<div>Loading...</div>}>
                <Layout isAuthenticated={isAuthenticated}>
                    <Routes>                       
                        {PublicRoutes.map(({component: Component, slug, exact}, index) => (
                            <Route path={`${slug}`} key={index} exact element={<Component />}/>
                        ))}
                    </Routes>
                </Layout>
            </Suspense>
        </Fragment>
    </Router>
  );
}

export default AppRoutes;