import React from 'react';
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from './App';

import reportWebVitals from './reportWebVitals.js';
// import {Provider} from 'react-redux'
// import store from './redux/store'
const root = createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
      {/* <Provider store={store()}> */}
        <App />
      {/* </Provider> */}
  </StrictMode>
);
reportWebVitals();