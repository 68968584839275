export const AOS_INIT_DEFAULT_PARAM = {
    disable: false,
    startEvent: 'DOMContentLoaded',
    initClassName: 'aos-init',
    animatedClassName: 'aos-animate',
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 99,           
    offset: 120,
    delay: 0,
    duration: 800,
    easing: 'ease',
    once: true,
    mirror: false,
    anchorPlacement: 'top-bottom',
}

export const IMAGES = {
    logo: "/assets/images/logo.svg",
}

export const EMAILJS_PARAMS = {
    SERVICE_ID: process.env.REACT_APP_EMAILJS_SERICE_ID,
    CONTACT_FORM_TEMPLATE_ID: process.env.REACT_APP_EMAILJS_CONTACT_FORM_TEMPLATE_ID,
    CONTACT_FORM_TEMPLATE_PARAMS: process.env.REACT_APP_EMAILJS_CONTACT_FORM_TEMPLATE_PARAMS,
    NEWS_LETTER_FORM_TEMPLATE_ID: process.env.REACT_APP_EMAILJS_NEWS_LETTER_FORM_TEMPLATE_ID,
    NEWS_LETTER_FORM_TEMPLATE_PARAMS: process.env.REACT_APP_EMAILJS_NEWS_LETTER_FORM_TEMPLATE_PARAMS,
}

export const RESPONSE_CODE = {
    "200": 200,
    "400": 400,
}

