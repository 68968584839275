import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AOS_INIT_DEFAULT_PARAM } from '../../app/constants';

const Header = React.lazy(() => import('./header'));
const Footer = React.lazy(() => import('./footer'));

const listener = () => {
    var dropdown = document.querySelector("header .dropdown");
    // document.body.classList.toggle('menu-toggle');
    dropdown.addEventListener("click",function(){
       document.body.classList.toggle('menu-toggle');
    })
};


function Layout(props) {    
    AOS.init(AOS_INIT_DEFAULT_PARAM);

    // useEffect(() => {
    //     addEventListener("click", listener);
    //     return () => {
    //         removeEventListener("click", listener);
    //     };
    //   }, []);
    return (
        <div className="App">
            <Header isAuthenticated={props.isAuthenticated} />
                {props.children}    
            <Footer isAuthenticated={props.isAuthenticated} />
        </div>
    )
}

export default Layout;