import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/general-style.css';

import AppRoutes from './routes';

const App = () => {
  
  useEffect(() => {
    const handleScroll = event => {
      let header = document.querySelector("header");
      if (window.scrollY > 0) {
          header.classList.add("sticky");
      } else {
          header.classList.remove("sticky");
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);

  // const {isAuthenticated, validateUserLoader} = useSelector(state => state.authenticateReducer)
  // const dispatch = useDispatch();

  const isAuthenticated = false;
  return  <>
      <AppRoutes isAuthenticated={isAuthenticated} />
  </>
}
export default App;
