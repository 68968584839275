import { lazy } from 'react';

export const PublicRoutes = [
  {
    slug: '/',
    component: lazy(() => import('../pages/homepage')),
    exact: true,
  },
  {
    slug: '/services',
    component: lazy(() => import('../pages/homepage')),
    exact: true,
  },
  {
    slug: '/technology',
    component: lazy(() => import('../pages/technology')),
    exact: true,
  },
  {
    slug: '/projects',
    component: lazy(() => import('../pages/projects')),
    exact: true,
  },
  {
    slug: '/project-details',
    component: lazy(() => import('../pages/projects/projectDetails')),
    exact: true,
  },
  {
    slug: '/about',
    component: lazy(() => import('../pages/about')),
    exact: true,
  },
  {
    slug: '/contact-us',
    component: lazy(() => import('../pages/contact')),
    exact: true,
  },
  {
    slug: '/what-we-do',
    component: lazy(() => import('../pages/whatwedo')),
    exact: true,
  },
];